import React, { useEffect } from 'react';
import ContentContainer from '@/components/elements/ContentContainer';
import { CSSTransition } from 'react-transition-group';
import tw from 'twin.macro';
import FlashMessageRender from '@/components/FlashMessageRender';
import SpinnerOverlay from './SpinnerOverlay';
import { ApplicationStore } from '@/state';
import cloudName, { CloudName } from '@/api/cloud/cloudName';
import useSWR from 'swr';
import { useStoreState } from 'easy-peasy';

export interface PageContentBlockProps {
    title?: string;
    className?: string;
    showFlashKey?: string;
}

const PageContentBlock: React.FC<PageContentBlockProps> = ({ title, showFlashKey, className, children }) => {
    useEffect(() => {
        if (title) {
            document.title = title;
        }
    }, [title]);
    const cloud = useStoreState((state: ApplicationStore) => state.user.data!.cloud);
    const uuid = useStoreState((state: ApplicationStore) => state.user.data!.uuid);
    const subcloud = useStoreState((state: ApplicationStore) => state.user.data!.subcloud);
    const subcloud_owner = useStoreState((state: ApplicationStore) => state.user.data?.subcloud_owner);
    const { data } = useSWR<CloudName>(
      ['/cloud/cloudname'],
      () => cloudName(subcloud_owner ? subcloud_owner : cloud ? uuid : '', subcloud ? true : false),
      {
        revalidateOnFocus: false,
      }
    );
    return (
        <CSSTransition timeout={150} classNames={'fade'} appear in>
                  {(subcloud || cloud) && !data ? (
        <div css={tw`w-full`}>
          <SpinnerOverlay visible={true} />
        </div>
      ) : (
            <>
                <ContentContainer css={tw`my-4 sm:my-10`} className={className}>
                    {showFlashKey && <FlashMessageRender byKey={showFlashKey} css={tw`mb-4`} />}
                    {children}
                </ContentContainer>
                <ContentContainer css={tw`mb-4`}>
                    <p css={tw`text-center text-neutral-500 text-xs`}>
                    {(subcloud || cloud) && data?.footer !== '' && data?.footer ? (
                <a
                  rel={'noopener nofollow noreferrer'}
                  href={data?.footerlink}
                  target={'_blank'}
                  css={tw`no-underline text-neutral-500 hover:text-neutral-300`}
                >
                  {data?.footer}
                </a>
              ) : (
                        <a
                            rel={'noopener nofollow noreferrer'}
                            href={'https://pterodactyl.io'}
                            target={'_blank'}
                            css={tw`no-underline text-neutral-500 hover:text-neutral-300`}
                        >
                            Tideo ApS
                        </a>
                         )}
                        &nbsp;&copy; 2020 - {new Date().getFullYear()} - Version 3.0
                    </p>
                </ContentContainer>
            </>
                )}
        </CSSTransition>
    );
};

export default PageContentBlock;
