import React from 'react';
import { Server } from '@/api/server/getServer';

export default ({ server }: { server: Server; className?: string }) => {

    return (
        <option value={`/server/${server.id}`}>
            {server.name}
        </option>
    );
};
