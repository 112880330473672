import { Action, action, Thunk, thunk, computed, Computed } from "easy-peasy"
import updateAccountEmail from "@/api/account/updateAccountEmail"

export interface UserData {
  uuid: string
  username: string
  email: string
  firstName: string
  lastName: string
  language: string
  rootAdmin: boolean
  useTotp: boolean
  createdAt: Date
  updatedAt: Date
  cloud: boolean;
  cloud_database: number;
  cloud_allocation: number;
  cloud_backup: number;
  cloud_cpu: number;
  cloud_ram: number;
  cloud_disk: number;
  cloud_users: number;
  cloud_servers: number;
  subcloud_owner: string;
  subcloud: string;
}

export interface UserStore {
  data?: UserData
  name: Computed<UserStore, string>
  setUserData: Action<UserStore, UserData>
  updateUserData: Action<UserStore, Partial<UserData>>
  updateUserEmail: Thunk<
    UserStore,
    { email: string; password: string },
    any,
    UserStore,
    Promise<void>
  >
}

const user: UserStore = {
  data: undefined,

  name: computed((state) => `${state.data?.firstName} ${state.data?.lastName}`),

  setUserData: action((state, payload) => {
    state.data = payload
  }),

  updateUserData: action((state, payload) => {
    // @ts-expect-error limitation of Typescript, can't do much about that currently unfortunately.
    state.data = { ...state.data, ...payload }
  }),

  updateUserEmail: thunk(async (actions, payload) => {
    await updateAccountEmail(payload.email, payload.password)

    actions.updateUserData({ email: payload.email })
  }),
}

export default user
