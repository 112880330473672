import { Action, action } from "easy-peasy";

export interface ModsStore {
    sourcemod: boolean;
    setSourcemod: Action<ModsStore, boolean>;
}

const mods: ModsStore = {
    sourcemod: false,
    setSourcemod: action((state, payload) => {
        state.sourcemod = payload;
    })
};

export default mods;