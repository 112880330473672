import React, { useEffect, useState } from "react"
import { Server } from "@/api/server/getServer"
import getServers from "@/api/getServers"
import ServerLink from "@/components/elements/ServerLink"
import Spinner from "@/components/elements/Spinner"
import { useStoreState } from "easy-peasy"
import { usePersistedState } from "@/plugins/usePersistedState"
import tw from "twin.macro"
import useSWR from "swr"
import { PaginatedResult } from "@/api/http"
import Pagination from "@/components/elements/Pagination"
import { useLocation, useHistory } from "react-router-dom"
import Select from "@/components/elements/Select"

export default () => {
  const { search } = useLocation()
  const defaultPage = Number(new URLSearchParams(search).get("page") || "1")

  const [page, setPage] = useState(
    !isNaN(defaultPage) && defaultPage > 0 ? defaultPage : 1
  )
  const uuid = useStoreState((state) => state.user.data!.uuid)

  const rootAdmin = useStoreState((state) => state.user.data!.rootAdmin)
  const [showOnlyAdmin, setShowOnlyAdmin] = usePersistedState(
    `${uuid}:show_all_servers`,
    false
  )
  const { data: servers } = useSWR<PaginatedResult<Server>>(
    ["/api/client/servers", showOnlyAdmin && rootAdmin, page],
    () =>
      getServers({
        page,
        type: showOnlyAdmin && rootAdmin ? "admin" : undefined,
      })
  )

  useEffect(() => {
    if (!servers) return
    if (servers.pagination.currentPage > 1 && !servers.items.length) {
      setPage(1)
    }
  }, [servers?.pagination.currentPage])

  const history = useHistory()

  const handleChange = (value: string) => {
    history.push(value)
  }

  return (
    <Select
      onChange={(event) => handleChange(event.target.value)}
      value={window.location.pathname.split("/", 3).join("/")}
      className={`!bg-gray-700 !text-gray-300`}
    >
      <option value={`/`} css="display:none;">
        Select a server
      </option>
      {!servers ? (
        <Spinner centered size={"small"} />
      ) : (
        <Pagination data={servers} onPageSelect={setPage}>
          {({ items }) =>
            items.length > 0 ? (
              items.map((server, index) => (
                <ServerLink
                  key={server.uuid}
                  server={server}
                  css={index > 0 ? tw`` : undefined}
                />
              ))
            ) : (
              <option value={`/`}>no servers</option>
            )
          }
        </Pagination>
      )}
    </Select>
  )
}
