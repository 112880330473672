import React, { forwardRef } from 'react';
import { Field as FormikField, FieldProps } from 'formik';
import Input from '@/components/elements/Input';
import Label from '@/components/elements/Label';

interface OwnProps {
    name: string;
    placeholder?: string;
    light?: boolean;
    label?: string;
    description?: string;
    validate?: (value: any) => undefined | string | Promise<any>;
}

type Props = OwnProps & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'name'>;

const Field = forwardRef<HTMLInputElement, Props>(
    ({ id, name, placeholder, light = false, label, description, validate, ...props }, ref) => (
        <FormikField innerRef={ref} name={name} validate={validate} type={props.type}>
            {({ field, form: { errors } }: FieldProps) => (
                <div>
                    {label && (
                        <Label htmlFor={id} isLight={light}>
                            {label}
                        </Label>
                    )}
                    {props.type === "checkbox" ? (
                        <>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <Input
                                    id={id}
                                    {...field}
                                    {...props}
                                    placeholder={placeholder}
                                    isLight={light}
                                    style={{marginRight: 5}}
                                    hasError={!!(errors[field.name])}
                                />
                                {description && (
                                    <p>{description}</p>
                                )}
                            </div>
                            {(errors[field.name]) && (
                                <p className={'input-help error'}>
                                    {(errors[field.name] as string).charAt(0).toUpperCase() +
                                        (errors[field.name] as string).slice(1)}
                                </p>
                            )}
                        </>
                    ) : (
                        <>
                            <Input
                                id={id}
                                {...field}
                                {...props}
                                placeholder={placeholder}
                                isLight={light}
                                hasError={!!(errors[field.name])}
                            />
                            {errors[field.name] ? (
                                <p className={'input-help error'}>
                                    {(errors[field.name] as string).charAt(0).toUpperCase() +
                                        (errors[field.name] as string).slice(1)}
                                </p>
                            ) : description && (
                                <p className={'input-help'}>{description}</p>
                            )}
                        </>
                    )}
                </div>
            )}
        </FormikField>
    )
);
Field.displayName = 'Field';

export default Field;
