import * as React from 'react';
import * as Icon from 'react-feather';
import tw from 'twin.macro';

export default () => {
    const toggleDarkMode = () => {
        if (localStorage.getItem('collapse') == 'true') {
            localStorage.setItem('collapse', 'null');
            document.body.classList.remove('collapsed');
        } else {
            localStorage.setItem('collapse', 'true');
            document.body.classList.add('collapsed');
        }
    };

    return (
        <button onClick={toggleDarkMode}>
            <div className='toggler' css={tw`mr-4`}>
                <Icon.Menu size={20}/>
            </div>
        </button>
    );
};
