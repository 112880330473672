import http, { FractalResponseData, FractalResponseList } from "@/api/http"
import {
  rawDataToServerAllocation,
  rawDataToServerEggVariable,
} from "@/api/transformers"
import { ServerEggVariable, ServerStatus } from "@/api/server/types"

export interface Allocation {
  id: number
  ip: string
  alias: string | null
  port: number
  notes: string | null
  isDefault: boolean
}

export interface Server {
  id: string
  internalId: number | string
  uuid: string
  eggId: number;
  owner: string;
  cloud: boolean;
  cloud_owner: string;
  name: string
  node: string
  crashlogUrl: string | null;
  nestId: number
  status: ServerStatus
  sftpDetails: {
    ip: string
    port: number
  }
  invocation: string
  dockerImage: string
  description: string
  limits: {
    memory: number
    swap: number
    disk: number
    io: number
    cpu: number
    threads: string
  }
  eggFeatures: string[]
  featureLimits: {
    databases: number
    allocations: number
    backups: number
  }
  isTransferring: boolean
  gitToken: string;
  variables: ServerEggVariable[]
  allocations: Allocation[]
  mcversion: string
  created_at: string
}

export const rawDataToServerObject = ({
  attributes: data,
}: FractalResponseData): Server => ({
  id: data.identifier,
  internalId: data.internal_id,
  uuid: data.uuid,
  name: data.name,
  node: data.node,
  eggId: data.egg_id,
  cloud: data.cloud,
  cloud_owner: data.cloud_owner,
  owner: data.owner_email,
  status: data.status,
  invocation: data.invocation,
  dockerImage: data.docker_image,
  crashlogUrl: data.crash_webhook,
  sftpDetails: {
    ip: data.sftp_details.ip,
    port: data.sftp_details.port,
  },
  description: data.description
    ? data.description.length > 0
      ? data.description
      : null
    : null,
  limits: { ...data.limits },
  eggFeatures: data.egg_features || [],
  featureLimits: { ...data.feature_limits },
  isTransferring: data.is_transferring,
  gitToken: data.git_token,
  variables: (
    (data.relationships?.variables as FractalResponseList | undefined)?.data ||
    []
  ).map(rawDataToServerEggVariable),
  allocations: (
    (data.relationships?.allocations as FractalResponseList | undefined)
      ?.data || []
  ).map(rawDataToServerAllocation),
  mcversion: data.mcversion,
  nestId: data.nest_id,
  created_at: data.created_at,
})

export default (uuid: string): Promise<[Server, string[]]> => {
  return new Promise((resolve, reject) => {
    http
      .get(`/api/client/servers/${uuid}`)
      .then(({ data }) =>
        resolve([
          rawDataToServerObject(data),
          // eslint-disable-next-line camelcase
          data.meta?.is_server_owner
            ? ["*"]
            : data.meta?.user_permissions || [],
        ])
      )
      .catch(reject)
  })
}
