import { ResultVersions, Versions } from "@/components/server/features/welcome/ServerVersionFeature"
import { Action, action } from "easy-peasy"

export interface WelcomeStore {
  whitelist: boolean
  setWhitelist: Action<WelcomeStore, boolean>

  version?: Versions
  setVersion: Action<WelcomeStore, Versions>

  jar: string
  setJar: Action<WelcomeStore, string>
}

const welcome: WelcomeStore = {
  whitelist: false,
  setWhitelist: action((state, payload) => {
    state.whitelist = payload
  }),

  version: undefined,
  setVersion: action((state, payload) => {
    state.version = payload
  }),

  jar: "Vanilla/1",
  setJar: action((state, payload) => {
    state.jar = payload
  }),
}

export default welcome
