import * as React from 'react';
import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faLayerGroup, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useStoreState } from 'easy-peasy';
import { ApplicationStore } from '@/state';
import SearchContainer from '@/components/dashboard/search/SearchContainer';
import tw, { theme } from 'twin.macro';
import styled from 'styled-components/macro';
import http from '@/api/http';
import SpinnerOverlay from '@/components/elements/SpinnerOverlay';
import ContentContainer from '@/components/elements/ContentContainer';
import DarkModeToggler from '@/components/elements/DarkModeToggler';
import ServerSelector from '@/components/ServerSelector';
import * as Icon from 'react-feather';
import useSWR from 'swr';
import cloudName, { CloudName } from '@/api/cloud/cloudName';

const Navigation = styled.div`
    ${tw`w-full`};
    margin-top:10px;
    
    & > div {
        ${tw`mx-auto w-full flex items-center`};

        & > .navigation-link{
            padding:10px 15px;
            cursor:pointer;
            max-width:300px;
            width:100%;
            display:flex;
            align-items:center;
            column-gap:10px;
            background-color:var(--secondary);
            border-radius:10px;
        }
    }
`;

const NavbarRight = styled.div`
    &{
        ${tw`flex items-center`};
        margin-left:auto;
    }
    & > a{
        cursor:pointer;
        padding:15px;
    }
`;

export default () => {
    const name = useStoreState((state: ApplicationStore) => state.settings.data!.name);
    const rootAdmin = useStoreState((state: ApplicationStore) => state.user.data!.rootAdmin);
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const cloud = useStoreState((state: ApplicationStore) => state.user.data!.cloud);
    const uuid = useStoreState((state: ApplicationStore) => state.user.data!.uuid);
    const subcloud = useStoreState((state: ApplicationStore) => state.user.data!.subcloud);
    const subcloud_owner = useStoreState((state: ApplicationStore) => state.user.data?.subcloud_owner);
    const { data, error } = useSWR<CloudName>(
      ['/cloud/cloudname'],
      () => cloudName(subcloud_owner ? subcloud_owner : cloud ? uuid : '', subcloud ? true : false),
      {
        revalidateOnFocus: false,
      }
    );
    React.useEffect(() => {
      if (error) {
        console.log(error);
      }
    }, [error]);
    const onTriggerLogout = () => {
        setIsLoggingOut(true);
        http.post('/auth/logout').finally(() => {
            // @ts-expect-error this is valid
            window.location = '/';
        });
    };

    return (
        <ContentContainer>
            <Navigation>
                <SpinnerOverlay visible={isLoggingOut} />
                <div css={tw`mx-auto w-full flex items-center`}>
                    <div className={`w-full max-w-[300px]`}>
                        <ServerSelector/>
                    </div>
                    <NavbarRight>
                        {rootAdmin &&
                        <a href={'/admin'} rel={'noreferrer'}>
                            Admin
                        </a>
                        }
                                                {cloud ? (
                  <a href={'/cloud/'}>
                    <Icon.Cloud size={20} />
                  </a>
              ) : <></>}
                        <a href='https://status.tideo.dk/' target="_blank" rel="noreferrer">
                            Status
                        </a>
                        <DarkModeToggler/>
                        <button onClick={onTriggerLogout}>
                            <Icon.LogOut size={20}/>
                        </button>
                       
                    </NavbarRight>
                </div>
            </Navigation>
        </ContentContainer>
    );
};
