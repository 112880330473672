import React, { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import tw from 'twin.macro';
import isEqual from 'react-fast-compare';

interface Props {
    icon?: IconProp;
    title: string | React.ReactNode;
    className?: string;
    children: React.ReactNode;
}

const TitledGreyBox = ({ icon, title, children, className }: Props) => (
    <div css={`border-radius:10px;overflow:hidden;`} className={className}>
        <div css={tw`shadow-md bg-neutral-700 px-6 py-5`}>
            <div css={tw`bg-neutral-700 rounded-t pb-3 border-b border-gray-50`}>
                {typeof title === 'string' ? (
                    <p css={tw`text-sm uppercase`}>
                        {icon && <FontAwesomeIcon icon={icon} css={tw`mr-2 text-neutral-300`} />}
                        {title}
                    </p>
                ) : (
                    title
                )}
            </div>
            <div css={tw`pt-3`}>{children}</div>
        </div>
    </div>
);

export default memo(TitledGreyBox, isEqual);
