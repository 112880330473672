import styled from 'styled-components/macro';
import tw from 'twin.macro';

const SubNavigation = styled.div`
    .toggler{
        display:none;
    }
    .darkmode & > div .darkModeLogo{
        display:block !important;
    }
    .darkmode & > div .lightModeLogo{
        display:none;
    }

    ${tw`w-full`};
    background-color:var(--secondary);
    min-height:100vh;
    display:flex;
    flex-direction:column;
    position:relative;

    &::after{
        content:'';
        position:fixed;
        top:0;
        left:0;
        width:250px;
        z-index:-1;
        height:100%;
        top:0;
        background-color:var(--secondary);
    }

    & > div:not(:first-of-type)::after{
        content:'';
        position:absolute;
        top:0;
        left:20px;
        width:calc(100% - 40px);
        height:1px;
        background-color:#D6D4D4;
        border-radius:10px;
    }

    & > div:last-of-type{
        margin-top:auto;
    }

    & > div {
        position:relative;
        padding-left:20px;
        padding-top:20px;
        padding-bottom:20px;

        & > .logo > a {
            & > .darkModeLogo{
                display:none;
            }
            & > img{
                padding-bottom:10px;
                padding-right:20px;
                width:168px;
                margin:auto;
            }
        }

        & > a, & > div {
            ${tw`block text-neutral-300 no-underline whitespace-nowrap`};
            padding:10px 15px;

            & > span{
                display: flex;
                align-items: center;
                column-gap: 10px;
            }

            &:hover {
                ${tw`text-neutral-100`};
            }

            &.active {
                ${tw`text-neutral-100`};
                background-color:var(--background-color);
                border-bottom-left-radius:10px;
                border-top-left-radius:10px;
                position:relative;
            }
            &.active::after{
                z-index:2;
                background-color:var(--secondary);
                top:-16px;
                right:0;
                width:16px;
                height:16px;
                position:absolute;
                border-bottom-right-radius:100px;
                content:'';
            }
            &.active::before{
                z-index:2;
                background-color:var(--secondary);
                bottom:-16px;
                right:0;
                width:16px;
                height:16px;
                position:absolute;
                border-top-right-radius:100px;
                content:'';
            }
            & > span > img{
                max-width:38px;
                width:100%;
                border-radius:50%;
            }
            &.active span::after{
                content:'';
                height:calc(100% + 32px);
                width:16px;
                right:0;
                top:-16px;
                position:absolute;
                background-color:var(--background-color);
            }
        }
    }
    @media only screen and (max-width: 533px) {
        .toggler{
            display:block;
        }
        &::after{
            display:none;
        }
        &{
            min-height:auto;
        }
        & > div:not(:first-of-type) {
            display:none;

            & > a, & > div{
                display:none;
            }
        }
        & > div > a,
        & > div > div{
            display:none;
        }
        .logo{
            display:flex !important;
            justify-content:space-between;
        }
        .collapsed &{
            & > div:not(:first-of-type) {
                display:block;

                & > a, & > div{
                    display:block;
                }
            }
            & > div > a,
            & > div > div{
                display:block;
            }
        }
    }
`;

export default SubNavigation;
