import React from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import NavigationBar from '@/components/NavigationBar';
import TransitionRouter from '@/TransitionRouter';
import { Redirect, useLocation } from 'react-router';
import Spinner from '@/components/elements/Spinner';
import { NotFound } from '@/components/elements/ScreenBlock';
import { useStoreState } from 'easy-peasy';
import SubNavigation from '@/components/elements/SubNavigation';
import CloudServersContainer from '@/components/cloud/servers/CloudServersContainer';
import CloudUsersContainer from '@/components/cloud/users/CloudUsersContainer';
import NewServerContainer from '@/components/cloud/servers/NewServerContainer';
import NewUserContainer from '@/components/cloud/users/NewUserContainer';
import CloudSettingsContainer from '@/components/cloud/settings/CloudSettingsContainer';
import styled from 'styled-components/macro';
import user from '@/state/user';
import NavBarToggler from '@/components/elements/NavBarToggler';
import Avatar from '@/components/Avatar';
import useSWR from 'swr';
import cloudName, { CloudName } from '@/api/cloud/cloudName';
import { ApplicationStore } from '@/state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import * as Icon from 'react-feather';

const FlexContainer = styled.div`
    &{
        display:flex;
        flex-wrap:wrap;
        width:100%;
    }
`;
const SideBar = styled.div`
    &{
        width:250px;
    }
    @media only screen and (max-width: 533px) {
        &{
            width:100%;
        }
    }
`;
const ContentBlock = styled.div`
    &{
        width:calc(100% - 250px);
    }
    @media only screen and (max-width: 533px){
        &{
            width:100%;
        }
    }
`;

export default () => {
  const location = useLocation();
  const user = useStoreState(state => state.user.data?.firstName);
  const cloud = useStoreState((state: ApplicationStore) => state.user.data!.cloud);
  const uuid = useStoreState((state: ApplicationStore) => state.user.data!.uuid);
  const subcloud = useStoreState((state: ApplicationStore) => state.user.data!.subcloud);
  const subcloud_owner = useStoreState((state: ApplicationStore) => state.user.data?.subcloud_owner);
  const { data, error } = useSWR<CloudName>(
    ['/cloud/cloudname'],
    () => cloudName(subcloud_owner ? subcloud_owner : cloud ? uuid : '', subcloud ? true : false),
    {
      revalidateOnFocus: false,
    }
  );
  React.useEffect(() => {
    if (error) {
      console.log(error);
    }
  }, [error]);
  return (
    <>
     <FlexContainer>
                  {location.pathname.startsWith('/cloud') && (
        <SideBar>
        <SubNavigation>
                    <div>
                        <div className='logo'>
                        {((subcloud || cloud) && data?.name !== '' && data?.name) || (data?.img && data?.img !== '') ? (
                                              <a href='/'><img src='https://tideo.dk/images/logo-dark.png' className='lightModeLogo'/><img src='https://tideo.dk/images/logo-light.png' className='darkModeLogo'/></a>

                ) : (
                  name
                )}
                            <NavBarToggler/>
                        </div>
                        <NavLink to={`/cloud/servers`}><span><Icon.Server size={20}/>Servers</span></NavLink>
            <NavLink to={`/cloud/users`}><span><Icon.Users size={20}/>Users</span></NavLink>
            <NavLink to={`/cloud/settings`}><span><Icon.Settings size={20}/>Settings</span></NavLink>
            <a href='https://mit.tideo.dk/knowledgebase/4/Cloud-Guide-Center' target="_blank" rel="noreferrer">
                            <span><Icon.HelpCircle size={20}/>Cloud Guide Center</span>
            </a>
                    </div>
                    <div>
                        <a href='/account'><span><Avatar/> {user}</span></a>
                    </div>
                </SubNavigation>
         </SideBar>
      )}
      <ContentBlock>
                <NavigationBar />
      <TransitionRouter>
        <React.Suspense fallback={<Spinner centered />}>
          <Switch location={location}>
            {cloud && (
              <>
                <Route path={'/cloud'} exact>
                  <Redirect to='/cloud/servers' />
                </Route>
                <Route path={'/cloud/servers'} exact>
                  <CloudServersContainer />
                </Route>
                <Route path={'/cloud/servers/new'} exact>
                  <NewServerContainer />
                </Route>
                <Route path={'/cloud/users'} exact>
                  <CloudUsersContainer />
                </Route>
                <Route path={`/cloud/users/new`} exact>
                  <NewUserContainer />
                </Route>
                <Route path={`/cloud/settings/`} exact>
                  <CloudSettingsContainer />
                </Route>
              </>
            )}

            <Route path={'*'}>
              <NotFound />
            </Route>
          </Switch>
        </React.Suspense>
      </TransitionRouter>
      </ContentBlock>
      </FlexContainer>

    </>
  );
};

