import * as React from 'react';
import * as Icon from 'react-feather';
import tw from 'twin.macro';

export default () => {
    if (localStorage.getItem('modes') == 'darkMode') {
        document.body.classList.add('darkmode');
        document.body.classList.remove('lightmode');
    } else {
        document.body.classList.remove('darkmode');
        document.body.classList.add('lightmode');
    }

    const toggleDarkMode = () => {
        if (localStorage.getItem('modes') == 'darkMode') {
            localStorage.setItem('modes', 'null');
            document.body.classList.remove('darkmode');
            document.body.classList.add('lightmode');
        } else {
            localStorage.setItem('modes', 'darkMode');
            document.body.classList.add('darkmode');
            document.body.classList.remove('lightmode');
        }
    };

    return (
        <button onClick={toggleDarkMode}>
            <div className='icon' css={tw`mr-4`}>
                <Icon.Moon size={20}/>
            </div>
        </button>
    );
};
